import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import student_center from '../img/student_center.png';
import comp_hex1 from '../img/comp_hex1.png';
import comp_hex2 from '../img/comp_hex2.png';
import vector_blue from '../img/vector_blue.png';
import comp_arrow from '../img/comp_arrow.png';

function Competition() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="competition-page">
            <PageHeader text="Trading Competition" />
            <div className="competition-page-container">
                <div className="comp-page-left">
                    <div className='comp-main-container'>
                        <div className="comp-title">
                            Coming Soon <br></br>in 2025!
                        </div>
                        <img src={vector_blue} alt="Vector" id="vector-blue-img" />
                        <div className="comp-desc">
                            Stay tuned for Trading at Georgia Tech’s inaugural trading competition, coming soon in Spring 2025. Please wait for applications to open to submit your bid to enter Georgia Tech’s most prestigious trading competition.  
                        </div>
                        <div className="comp-app-button-container">
                            <div className="comp-app-button">
                                Application
                            </div>
                        </div>
                    </div>
                </div>
                {screenWidth >= 1800 && (
                    <div className="comp-page-right">
                        <img src={comp_hex1} alt="Hexagon" id="hex1" />
                        <img src={comp_hex2} alt="Hexagon" id="hex2" />
                        <img src={student_center} alt="Student Center" id="sc-img" />
                        <img src={comp_arrow} alt="Arrow" id="comp-arrow" />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Competition;