import React, { useState, useEffect } from 'react';
import trading_gt_logo from '../img/trading_gt_logo.png';
import { Link } from 'react-router-dom';
import SideNavBar from './SideNavBar';

function NavBar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [isBlackBoxVisible, setIsBlackBoxVisible] = useState(false);

    const handleToggle = () => {
        setIsNavExpanded(prevState => !prevState);
        setIsBlackBoxVisible(prevState => !prevState);
    };

    const handleCloseSideNavBar = () => {
        setIsNavExpanded(false);
        setIsBlackBoxVisible(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1850) {
                setIsNavExpanded(false);
                setIsBlackBoxVisible(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <nav className="nav-bar-outer-container">
            <div className='nav-bar-inner-container'>
                <Link to="/">
                    <img src={trading_gt_logo} alt="Trading @ GT Logo" className='nav-bar-logo' />
                </Link>
                <button className="nav-bar-toggle" onClick={handleToggle}>
                    <span className="hamburger-icon"></span>
                    <span className="hamburger-icon"></span>
                    <span className="hamburger-icon"></span>
                </button>
                <div className={`nav-links ${isNavExpanded ? "expand" : "collapse"}`}>
                    <div className="nav-link-adjuster">
                        <Link to="/about" className='nav-bar-pages'>ABOUT</Link>
                        <Link to="/projects" className='nav-bar-pages'>PROJECTS</Link>
                        <Link to="/leadership" className='nav-bar-pages'>LEADERSHIP</Link>
                        <Link to="/partners" className='nav-bar-pages'>PARTNERS</Link>
                        <Link to="/placements" className='nav-bar-pages'>PLACEMENTS</Link>
                        <Link to="/competition" className='nav-bar-pages'>COMPETITION</Link>
                        <a href="mailto:tradingclubgt@gmail.com" className="nav-bar-contact-button">CONTACT US</a>
                    </div>
                </div>
                {isBlackBoxVisible && <SideNavBar onClose={handleCloseSideNavBar} />}
            </div>
        </nav>
    );
}

export default NavBar;