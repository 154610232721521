import React from 'react';
import adi_akula from '../img/adi_akula.png';
import gio from '../img/gio.png';
import khushi from '../img/khushi.png';
import ayush from '../img/ayush.png';
import joey from '../img/joey.png';
import kelly from '../img/kelly.png';
import sneha from '../img/sneha.png';
import sai from '../img/sai.png';
import advait from '../img/advait.png';
import sriharsha from '../img/sriharsha.png';
import stephanie from '../img/stephanie.png';
import saara from '../img/saara.png';

const ExecTable2 = () => {
    return (
        <div class="exec-table">
            <div class="exec-row" id="exec-row-1-2">
                <div class="exec-cell-2">
                    <img src={adi_akula} alt='adi_akula' class="exec-img"/>
                    <div class="exec-pos">President</div>
                    <div class="exec-name">Aditya Akula</div>
                </div>
                <div class="exec-cell-2">
                    <img src={gio} alt='gio_hsu' class="exec-img"/>
                    <div class="exec-pos">Vice President</div>
                    <div class="exec-pos2">Head of Quant Dev</div>
                    <div class="exec-name">Giovanni Hsu</div>
                </div>
            </div>
            <div class="exec-row">
                <div class="exec-cell-2">
                    <img src={khushi} alt='khushi' class="exec-img"/>
                    <div class="exec-pos">Vice President</div>
                    <div class="exec-name">Khushi Afre</div>
                </div>
                <div class="exec-cell-2">
                    <img src={ayush} alt='ayush' class="exec-img"/>
                    <div class="exec-pos">Head of Quant Dev</div>
                    <div class="exec-name">Ayush Gundawar</div>
                </div>
            </div>
            <div class="exec-row">
                <div class="exec-cell-2">
                    <img src={sneha} alt='sneha' class="exec-img"/>
                    <div class="exec-pos">Operations</div>
                    <div class="exec-name">Sneha Sureshkumar</div>
                </div>
                <div class="exec-cell-2">
                    <img src={sai} alt='sai' class="exec-img"/>
                    <div class="exec-pos">Front-End Development</div>
                    <div class="exec-name">Saisaketh Koppu</div>
                </div>
            </div>
            <div class="exec-row">
                <div class="exec-cell-2">
                    <img src={saara} alt='saara' class="exec-img"/>
                    <div class="exec-pos">Treasurer</div>
                    <div class="exec-name">Saara Anam</div>
                </div>
                <div class="exec-cell-2">
                    <img src={advait} alt='advait' class="exec-img"/>
                    <div class="exec-pos" id="advait-pos">Trading Comp & Corporate Relations</div>
                    <div class="exec-name">Advait Sepuri</div>
                </div>
            </div>
            <div class="exec-row"> 
                <div class="exec-cell-2">
                    <img src={sriharsha} alt='sriharsha' class="exec-img"/>
                    <div class="exec-pos">Trading Comp</div>
                    <div class="exec-name">Sriharsha Kocherla</div>
                </div>
                <div class="exec-cell-2">
                    <img src={kelly} alt='kelly' class="exec-img"/>
                    <div class="exec-pos">Marketing</div>
                    <div class="exec-name">Kelly Lin</div>
                </div>
            </div>
            <div class="exec-row">
                <div class="exec-cell-2">
                    <img src={joey} alt='joey' class="exec-img"/>
                    <div class="exec-pos">Marketing</div>
                    <div class="exec-name">Joey Shi</div>
                </div>
                <div class="exec-cell-2">
                    <img src={stephanie} alt='stephanie' class="exec-img"/>
                    <div class="exec-pos">Corporate Relations</div>
                    <div class="exec-pos2">Head of Market Insights</div>
                    <div class="exec-name">Stephanie Panovska</div>
                </div>
            </div>
        </div>
    );
};

export default ExecTable2;