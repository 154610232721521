import React from 'react';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import citadel from '../img/citadel.png';
import imc from '../img/imc.png';
import drw from '../img/drw.png';
import old_mission from '../img/old_mission.png';

function Partners() {
    return (
        <div class="partners-page">
            <PageHeader text="Our Partners" />
            <div class="partners-page-container">
                <div class="partners-page-table">
                    <a href="https://www.citadel.com/" target="_blank" rel="noopener noreferrer" class="partners-page-link"><img href="https://www.citadel.com/" target="_blank" rel="noopener noreferrer" src={citadel} alt="Citadel" class="partners-page-img" /></a>
                    <a href="https://www.imc.com/us/" class="partners-page-link" target="_blank" rel="noopener noreferrer"><img src={imc} alt="IMC" class="partners-page-img" /></a>
                    <a href="https://drw.com/" class="partners-page-link" target="_blank" rel="noopener noreferrer"><img src={drw} alt="DRW" class="partners-page-img" /></a>
                    <a href="https://www.oldmissioncapital.com/" class="partners-page-link" target="_blank" rel="noopener noreferrer"><img src={old_mission} alt="Old Mission" class="partners-page-img" /></a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Partners;