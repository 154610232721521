import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import AboutContainer from '../components/AboutContainer';
import AboutContainer1 from '../components/AboutContainer-1';

function About() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1250);

    useEffect(() => {
        const handleResize = () => {
            console.log("Window width:", window.innerWidth); // Log window width
            setIsMobileView(window.innerWidth < 1250);
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <PageHeader text="Our Story" />
            {isMobileView ? (
                <AboutContainer1 />
            ) : (
                <AboutContainer />
            )}
            <Footer />
        </div>
    );
}

export default About;