import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import ProjectContainer2 from '../components/ProjectContainer-2';
import Footer from '../components/Footer';
import ProjectContainer1 from '../components/ProjectContainer-1';


function Projects() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 700);

    useEffect(() => {
        const handleResize = () => {
            console.log("Window width:", window.innerWidth); // Log window width
            setIsMobileView(window.innerWidth < 700);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Initial check
        handleResize();
    
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="parent-project-container">
            <PageHeader text="Our Projects" />
            {isMobileView ? (
                <ProjectContainer1 />
            ) : (
                <ProjectContainer2 />
            )}
            <Footer />
        </div>
    );
}

export default Projects;