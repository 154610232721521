import React from 'react';
import vector from '../img/vector.png';
import line from '../img/line.png';
import quant_research from '../img/quant_research.png';
import quant_dev from '../img/quant_dev.png';
import disc_trad from '../img/disc_trad.png';
import market_insights from '../img/market_insights.png';   
import jane_street from '../img/jane_street.png';
import de_shaw from '../img/de_shaw.png';
import citadel from '../img/citadel.png';
import imc from '../img/imc.png';
import drw from '../img/drw.png';
import akuna from '../img/akuna.png';
import optiver from '../img/optiver.png';
import millenium from '../img/millenium.png';
import old_mission from '../img/old_mission.png';
import Footer from '../components/Footer';

function Home() {
    return (
        <>
            <div className='home-main'>
                <div className='home-main-container'>
                    <div class="home-title">
                        Georgia Tech's Leading Quant Finance Org
                    </div>
                    <img src={vector} alt='bg' class="vector-img"/> 
                    <div class="home-desc">
                        At Trading at Georgia Tech, we develop high-performance trading infrastructure and conduct rigorous quantitative research in order to build and run reliable and profitable trading strategies. Join us to <strong>access industry opportunities</strong>, <strong>technical projects</strong>, and <strong>educational resources</strong>. 
                    </div>
                    <div class="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div class="home-section" id="home-sector-section">
                <div class="home-section-title">
                    <img src={line} alt='line' class="line-img"/>
                    Our Sectors
                    <img src={line} alt='line' class="line-img"/>
                </div>
                <div class="sector-table">
                    <div class="sector-cell">
                        <img src={quant_research} alt='quant_research' class="sector-img"/>
                        <div class="sector-title">Quantitative Research</div>
                        <div class="sector-desc">Model financial markets, create trading strategies from anomalies, and use granular options data for equities and live cryptocurrency data.</div>
                    </div>
                    <div class="sector-cell">
                        <img src={quant_dev} alt='quant_dev' class="sector-img"/>
                        <div class="sector-title">Quantitative Development</div>
                        <div class="sector-desc">Develop high-performance, multi-strategy trading systems with low-latency infrastructure and design high-throughput distributed systems.</div>
                    </div>
                    <div class="sector-cell">
                        <img src={disc_trad} alt='disc_trad' class="sector-img"/>
                        <div class="sector-title">Bootcamp</div>
                        <div class="sector-desc">Learn the ins and outs of analyzing markets through various quant finance concepts, data analysis techniques, and domain-specific technologies.</div>
                    </div>
                    <div class="sector-cell">
                        <img src={market_insights} alt='market_insights' class="sector-img"/>
                        <div class="sector-title">Market<br></br> Insights</div>
                        <div class="sector-desc">Conduct market research by publishing the Weekly Market Commentary, analyzing various asset classes to understand market trends.</div>
                    </div>
                </div>
            </div>
            <div class="home-section" id="home-placements-section">
                <div class="home-section-title">
                    <img src={line} alt='line' class="line-img"/>
                    Our Placements
                    <img src={line} alt='line' class="line-img"/>
                </div>
                <div class="placement-page-table">
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://www.janestreet.com" target="_blank" rel="noopener noreferrer">
                                <img src={jane_street} alt='jane_street' class="placement-page-img" />
                            </a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.deshaw.com" target="_blank" rel="noopener noreferrer">
                                <img src={de_shaw} alt='de_shaw' class="placement-page-img"/>
                            </a>
                        </div>
                    </div>
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://www.citadel.com" target="_blank" rel="noopener noreferrer">
                                <img src={citadel} alt='citadel' class="placement-page-img"/>
                            </a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.imc.com" target="_blank" rel="noopener noreferrer">
                                <img src={imc} alt='imc' class="placement-page-img"/>
                            </a>
                        </div>
                    </div>
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://www.drw.com" target="_blank" rel="noopener noreferrer">
                                <img src={drw} alt='drw' class="placement-page-img"/>
                            </a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.akunacapital.com" target="_blank" rel="noopener noreferrer">
                                <img src={akuna} alt='akuna' class="placement-page-img"/>
                            </a>
                        </div>
                    </div>
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://www.optiver.com" target="_blank" rel="noopener noreferrer">
                                <img src={optiver} alt='optiver' class="placement-page-img"/>
                            </a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.mlp.com/" target="_blank" rel="noopener noreferrer">
                                <img src={millenium} alt='millenium' class="placement-page-img"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="home-section" id="home-partners-section">
                <div class="home-section-title">
                    <img src={line} alt='line' class="line-img"/>
                    Our Partners
                    <img src={line} alt='line' class="line-img"/>
                </div>
                <div class="placement-table" id="partners-table">
                    <div class="placement-row">
                        <div class="placement-cell">
                            <a href="https://www.citadel.com" target="_blank" rel="noopener noreferrer">
                                <img src={citadel} alt='citadel' class="partners-img"/>
                            </a>
                        </div>
                        <div class="placement-cell">
                            <a href="https://www.oldmissioncapital.com/" target="_blank" rel="noopener noreferrer">
                                <img src={old_mission} alt='old_mission' class="partners-img"/>
                            </a>
                        </div>
                    </div>
                    <div class="placement-row">
                        <div class="placement-cell">
                            <a href="https://www.drw.com" target="_blank" rel="noopener noreferrer">
                                <img src={drw} alt='drw' class="partners-img"/>
                            </a>
                        </div>
                        <div class="placement-cell">
                            <a href="https://www.imc.com" target="_blank" rel="noopener noreferrer">
                                <img src={imc} alt='imc' class="partners-img"/>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* <div class="home-section" id="home-partners-section">
                <div class="home-section-title">
                    <img src={line} alt='line' class="line-img"/>
                    Our Partners
                    <img src={line} alt='line' class="line-img"/>
                </div>
                <div class="partners-table">
                    <div class="partners-row">
                        <div class="partners-cell">
                            <a href="https://www.citadel.com" target="_blank" rel="noopener noreferrer">
                                <img src={citadel} alt='citadel' class="partners-img"/>
                            </a>
                        </div>
                        <div class="partners-cell">
                            <a href="https://www.oldmissioncapital.com/" target="_blank" rel="noopener noreferrer">
                                <img src={old_mission} alt='old_mission' class="partners-img"/>
                            </a>
                        </div>
                    </div>
                    <div class="partners-row">
                        <div class="partners-cell">
                            <a href="https://www.drw.com" target="_blank" rel="noopener noreferrer">
                                <img src={drw} alt='drw' class="partners-img"/>
                            </a>
                        </div>
                        <div class="partners-cell">
                            <a href="https://www.imc.com" target="_blank" rel="noopener noreferrer">
                                <img src={imc} alt='imc' class="partners-img"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>    
    );
}

export default Home;