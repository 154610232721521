import React from 'react';
import about_intro from '../img/about_intro.png';
import trad from '../img/trad.png';
import hex_3 from '../img/hex_3.png';
import arrow_3 from '../img/arrow_3.png';
import tech_tower from '../img/tech_tower.png';
import hex_4 from '../img/hex_4.png';
import arrow_4 from '../img/arrow_4.png';
import about_2 from '../img/about_2.png';
import about_3 from '../img/about_3.png';

const AboutContainer = () => {
    return (
        <div class="about-page-container">
            <div class="about-row" id="about-row-1">
                <div class="about-cell" id="about-intro">
                    <img src={about_intro} alt="about_intro" />
                </div>
                <div class="about-cell" id="about-20">
                    <img src={trad} alt="trad" id="trad"/>
                    <div class="about-cell-1-hex-3">
                        <img src={hex_3} alt="hex_3" id="hex_3" />
                    </div>
                    <img src={arrow_3} alt="arrow_3" id="arrow_3" />
                </div>
            </div>
            <div class="about-row" id="about-row-2">
                <div class="about-cell" id="about-intro">
                    <img src={tech_tower} alt="tech_tower" id="tech_tower" />
                    <img src={hex_4} alt="hex_4" id="hex_4" />
                    <img src={arrow_4} alt="arrow_4" id="arrow_4" />
                </div>
                <div class="about-cell">
                    <img src={about_2} alt="about_2" id="about_2" />
                </div>
            </div>
            <div class="about-row" id="about-row-3">
                <img src={about_3} alt="about_3" id="about_3" />
            </div>
        </div>
    );
};

export default AboutContainer;