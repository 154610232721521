import React from 'react';
import page_hex from '../img/page_hex.png';

const PageHeader = (props) => {
    return (
        <div class="page-header"> 
            <div class="page-header-text">{props.text}</div>
            <img src={page_hex} alt="page_hex" id="page-hex"/>
        </div>
    );
};

export default PageHeader;