import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faDiscord } from '@fortawesome/free-brands-svg-icons';
import footer_hex from '../img/footer_hex.png';
import small_logo from '../img/small_logo.png';

const Footer = () => {
    return (
        <footer>
            <img src={footer_hex} alt='footer_hex' className="footer-hex"/>
            <div className="footer-right">
                <div className="footer-social">
                    <a href="https://www.linkedin.com/company/tradingclubgt/" target="_blank" rel="noopener noreferrer" class="footer-icons">
                        <FontAwesomeIcon class="footer-icon" icon={faLinkedin} />
                    </a>
                    <a href="https://www.instagram.com/tradingatgt/" target="_blank" rel="noopener noreferrer" class="footer-icons">
                        <FontAwesomeIcon class="footer-icon" icon={faInstagram} />
                    </a>
                    <a href="https://discord.gg/HXYYVHkD6R" target="_blank" rel="noopener noreferrer" class="footer-icons">
                        <FontAwesomeIcon class="footer-icon" icon={faDiscord} />
                    </a>
                </div>
                <img src={small_logo} alt='small_logo' className="small-logo"/>
            </div>
        </footer>
    );
};

export default Footer;