import React from 'react';
import capitol from '../img/capitol.png';
import prediction_markets from '../img/prediction_markets.png';
import exchange from '../img/exchange.png';
import parlays from '../img/parlays.png';
import basketball from '../img/basketball.png';
import poker from '../img/poker.png';
import spreads from '../img/spreads.png';
import poker_bot from '../img/poker_bot.png';

const ProjectContainer1 = () => {
    return (
        <div className="project-container-1">
            <div className="project-cell-1">
                <img src={capitol} alt="Capitol" className="project-img-1" />
                <img src={prediction_markets} alt="Prediction Markets" className="project-title-1" />
                <ul>
                    <li>Several prediction markets have come up in the past few years – Polymarket, Kalshi, and the original PredictIt.</li>
                    <li>These markets allow participants to bet on the outcome of real world events, similar to the way traditional markets do.</li>
                    <li><strong>Goal</strong>: Find a way to make money in these political prediction markets - particularly primary elections, results, and turnouts.</li>
                </ul>
                <div className="apply-button-1">Apply</div>
            </div>
            <div className="project-cell-1">
                <img src={exchange} alt="Exchange" className="project-img-1" />
                <img src={parlays} alt="Parlays" className="project-title-1" />
                <ul>
                    <li><strong>Idea</strong>: Platforms like PrizePicks and Underdog have become legal in Georgia in recent years, not offering full legal sports betting but offering the opportunity to bet on player props.</li>
                    <li>However, many of these sites still don’t fully account for correlations.</li>
                    <li><strong>Goal</strong>: Find +EV player parlays based on correlation, and build solid infrastructure to automatically do this for us every day.</li>
                </ul>
                <div className="apply-button-1">Apply</div>
            </div>
            <div className="project-cell-1" id="project-cell-1-2">
                <img src={basketball} alt="Basketball" className="project-img-1" />
                <img src={spreads} alt="Spreads" className="project-title-1" />
                <ul>
                    <li><strong>Idea</strong>: College basketball is a high variance regime; the gap between the best and worst teams is quite large, so there are opportunities to build effective predictive models.</li>
                    <li><strong>Goal</strong>: Build a profitable college basketball (or other sport) spread model.</li>
                </ul>
                <div className="apply-button-1">Apply</div>
            </div>
            <div className="project-cell-1" id="project-cell-1-2" >
                <img src={poker} alt="Poker" className="project-img-1" />
                <img src={poker_bot} alt="Poker Bot" className="project-title-1" />
                <ul>
                    <li><strong>Goal</strong>: Build a poker bot, probably for No Limit Hold ‘Em, but maybe for PLO or a different variant, depending on interest.</li>
                </ul>
                <div className="apply-button-1">Apply</div>
            </div>
        </div>
    );
};

export default ProjectContainer1;