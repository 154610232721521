import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import line from '../img/line.png';
import ExecTable from '../components/ExecTable';
import ExecTable2 from '../components/ExecTable-2';
import ExecTable1 from '../components/ExecTable-1';

function Leadership() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1040);
    const [isSmallMobileView, setIsSmallMobileView] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1040);
            setIsSmallMobileView(window.innerWidth < 630);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <PageHeader text="Our Leadership" />
            <div className="leadership-page-container">
                <div className="home-section-title">
                    <img src={line} alt='line' className="line-img"/>
                    2023-24 Executive Board
                    <img src={line} alt='line' className="line-img"/>
                </div>
                {isSmallMobileView ? <ExecTable1 /> : (isMobileView ? <ExecTable2 /> : <ExecTable />)}
            </div>
            <Footer />
        </div>
    );
}

export default Leadership;