import React from 'react';
import about_intro from '../img/about_intro.png';
import about_2 from '../img/about_2.png';
import about_3 from '../img/about_3.png';

const AboutContainer = () => {
    return (
        <div class="about-page-container-1">
            <img src={about_intro} alt="about_intro" id="about_intro-1" />
            <img src={about_2} alt="about_2" id="about_2-1" />
            <img src={about_3} alt="about_3" id="about_3-1" />
        </div>
    );
};

export default AboutContainer;