import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import trading_gt_logo from '../img/trading_gt_logo.png';

const SideNavBar = ({ onClose }) => {
    const [isNavVisible, setIsNavVisible] = useState(true);

    const handleClose = () => {
        setIsNavVisible(false);
        onClose(); // Notify NavBar that the side nav bar is closed
    };

    return (
        isNavVisible && (
            <div className="side-nav-bar-container">
                <div className="transparent-side" onClick={handleClose}></div>
                <div className="opaque-side">
                    <button className="close-button" onClick={handleClose}>
                        <FontAwesomeIcon icon={faArrowRight} style={{ color: '#30376B' }} />
                    </button>
                    <Link to="/" className="side-nav-logo-container" onClick={handleClose}>
                        <img src={trading_gt_logo} alt="Trading @ GT Logo" id="side-nav-logo"/>
                    </Link>
                    <div className="side-nav-bar-links-container">
                        <Link to="/about" className='side-nav-bar-pages' onClick={handleClose}>ABOUT</Link>
                        <Link to="/projects" className='side-nav-bar-pages' onClick={handleClose}>PROJECTS</Link>
                        <Link to="/leadership" className='side-nav-bar-pages' onClick={handleClose}>LEADERSHIP</Link>
                        <Link to="/partners" className='side-nav-bar-pages' onClick={handleClose}>PARTNERS</Link>
                        <Link to="/placements" className='side-nav-bar-pages' onClick={handleClose}>PLACEMENTS</Link>
                        <Link to="/competition" className='side-nav-bar-pages' onClick={handleClose}>COMPETITION</Link>
                        <a href="mailto:tradingclubgt@gmail.com" className="side-nav-bar-contact-button">CONTACT US</a>
                    </div>
                </div>
            </div>
        )
    );
};

export default SideNavBar;