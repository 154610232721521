import React from 'react';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import jane_street from '../img/jane_street.png';
import de_shaw from '../img/de_shaw.png';
import citadel from '../img/citadel.png';
import imc from '../img/imc.png';
import drw from '../img/drw.png';
import akuna from '../img/akuna.png';
import optiver from '../img/optiver.png';
import millenium from '../img/millenium.png';

function Placements() {
    return (
        <div class="placements-page">
            <PageHeader text="Our Placements" />
            <div class="placement-page-container">
                <div class="placement-page-table">
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://www.janestreet.com/" target="_blank" rel="noopener noreferrer"><img src={jane_street} alt='jane_street' class="placement-page-img"/></a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.deshaw.com/" target="_blank" rel="noopener noreferrer"><img src={de_shaw} alt='de_shaw' class="placement-page-img"/></a>
                        </div>
                    </div>
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://www.citadel.com/" target="_blank" rel="noopener noreferrer"><img src={citadel} alt='citadel' class="placement-page-img"/></a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.imc.com/us/" target="_blank" rel="noopener noreferrer"><img src={imc} alt='imc' class="placement-page-img"/></a>
                        </div>
                    </div>
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://drw.com/" target="_blank" rel="noopener noreferrer"><img src={drw} alt='drw' class="placement-page-img"/></a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://akunacapital.com/" target="_blank" rel="noopener noreferrer"><img src={akuna} alt='akuna' class="placement-page-img"/></a>
                        </div>
                    </div>
                    <div class="placement-page-row">
                        <div class="placement-page-cell">
                            <a href="https://optiver.com/" target="_blank" rel="noopener noreferrer"><img src={optiver} alt='optiver' class="placement-page-img"/></a>
                        </div>
                        <div class="placement-page-cell">
                            <a href="https://www.mlp.com/" target="_blank" rel="noopener noreferrer"><img src={millenium} alt='millenium' class="placement-page-img"/></a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Placements;