import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import NavBar from './components/NavBar.js';
import Home from './pages/Home.js';
import About from './pages/About.js';
import Projects from './pages/Projects.js';
import Leadership from './pages/Leadership.js';
import Partners from './pages/Partners.js';
import Placements from './pages/Placements.js';
import Competition from './pages/Competition.js';

function App() {
  return (
    <div>
      
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path='/projects' element={<Projects />}></Route>
          <Route path='/leadership' element={<Leadership />}></Route>
          <Route path='/partners' element={<Partners />}></Route>
          <Route path='/placements' element={<Placements />}></Route>
          <Route path='/competition' element={<Competition />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
